.form {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.form-img-title {
  height: 40rem;
  margin: 0;
  padding: 0;
  background-image: url("../../public/images/pexels-braincontour-5032708.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  position: inherit;
}
.form-div-title {
  display: flex;
  justify-content: center;
  margin-top: -2rem;
}
.form-title {
  border-radius: 30px;
  color: white;
  display: inline-block;
  background-color: rgb(255, 0, 136);
  text-align: center;
  padding: 1rem 1.5rem;
}
.form-contact {
  display: flex;
  justify-content: space-around;
  height: 30rem;
}
.form-h2 {
  display: flex;
  justify-content: center;
}
.form-coords {
  display: flex;
  justify-content: space-around;
  font-weight: bolder;
  font-size: 1.2rem;
}
.form-field {
  background-color: rgb(206, 206, 206);
  border-radius: 20px;
  text-align: center;
}
#form-lastname {
  display: flex;
  flex-direction: column;
}
#form-firstname {
  display: flex;
  flex-direction: column;
}
#form-email {
  display: flex;
  flex-direction: column;
}
#form-phone {
  display: flex;
  flex-direction: column;
}
#form-subject {
  display: flex;
  flex-direction: column;
}
.form-button {
  background-color: rgb(206, 206, 206);
  border-radius: 20px;
}
#form-contact {
  appearance: none;
}
#subject {
  appearance: none;
}
#form-message {
  display: flex;
  flex-direction: column;
}
#message {
  background-color: rgb(206, 206, 206);
  border-radius: 20px;
  padding: 1.5rem;
  font-size: 1rem;
}
#form-send {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  margin-top: 1rem;
}
.NosPartenairesFFC {
  color: black;
  font-size: 30px;
}
.NosPartenairesFC {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 1300px) {
  .form-contact {
    margin-top: 10rem;
  }
  .form-input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }
  .form-name {
    display: flex;
    flex-direction: row;
  }
  .form-label {
    font-size: 1.5rem;
    font-weight: bold;
  }
  #form-lastname {
    margin-right: 6rem;
  }
  #email {
    width: 35rem;
  }
  #phone {
    width: 35rem;
  }
  #subject {
    width: 35rem;
  }
  .form-field {
    height: 3rem;
  }
  .form-textarea {
    display: flex;
    justify-content: center;
    width: 50%;
  }
  #message {
    width: 35rem;
    height: 100%;
  }
  .form-button {
    width: 8rem;
    height: 3rem;
    margin-top: 2rem;
  }
  .form-submit {
    margin-top: 3rem;
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .form-coords {
    margin-block: 5rem;
  }
}

@media screen and (max-width: 1299px) {
  .form-img-title {
    margin: 0;
    height: 15rem;
  }
  .form-title {
    font-size: 0.8rem;
  }
  .form-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
  }
  .form-field {
    height: 2rem;
  }
  #form-lastname {
    align-items: center;
  }
  #form-firstname {
    align-items: center;
  }
  #form-email {
    align-items: center;
  }
  #form-phone {
    align-items: center;
  }
  #form-subject {
    align-items: center;
  }
  #subject {
    width: 100%;
  }
  #form-message {
    align-items: center;
  }
  #message {
    height: 12rem;
    padding: 1rem;
  }
  /* .form-button-text {
    display: flex;
    flex-direction: column-reverse;
  } */
  .form-h2 {
    margin-top: 3rem;
  }
  .form-coords {
    flex-direction: column;
    align-items: center;
  }
  .form-div-button {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  .form-submit {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
  .form-button {
    width: 10rem;
    height: 2rem;
  }
}
.contact {
  font-size: 1.4rem;
}