.pageContainer {
  display: flex;
  flex-direction: column;
}

.pageContainer header {
  position: relative;
}

.pageContainer header img {
  height: 450px;
  width: 100%;
  object-fit: cover;
}

.pageContainer .pageTitle {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -18px;
  font-size: 24px;
  text-transform: uppercase;
  background-color: #12b5f1;
  color: white;
  padding: 10px 30px;
  border-radius: 50px;
}

.pageContainer .animationTitles {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pageContainer .animationTitles h1 {
  text-align: center;
  text-transform: uppercase;
  margin: 30px 0;
}

.pageContainer .animationTitles h2 {
  margin: 10px 35px;
  color: #205da9;
  text-transform: uppercase;
}

.pageContainer h3 {
  text-align: center;
  margin-top: 30px;
}

.pageContainer .categoriesContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px auto 0;
}

.categoriesContainer .buttonCategory {
  cursor: unset;
}
.categoriesContainer .buttonCategory:hover {
  box-shadow: none;
}

.pageBody {
  display: flex;
  flex-direction: row;
  margin: 20px 40px 70px;
  min-height: 450px;
}

.pageBodyActivity {
  margin-top: 70px;
}
.pageContainer .pagePreviewOfImages {
  display: flex;
  justify-content: center;
  min-width: 50%;
  height: 450px;
}

.pagePreviewOfImages img {
  height: 100%;
  max-width: 100%;
  min-width: 100%;
  object-fit: cover;
}

.btn-OurReco {
  position: absolute;
  width: 450px;
  height: 400px;
  transition: transform 0.75s;
  border: none;
  background-color: transparent;
}

.textSection {
  width: 100%;
}

.textSection h1 {
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  margin: 15px 0;
}

.textSection h2 {
  font-size: 24px;
  text-transform: capitalize;
  margin: 10px 0;
  color: #205da9;
}

.secondBody {
  margin: 0 70px;
  max-width: calc(100% - 140px);
}

/* CSS du carousel */
.btn-OurReco.active {
  transform: scale(1) translateX(0px);
  z-index: 7005;
  transition: 0.75s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.btn-OurReco.next1 {
  transform: scale(0.9) translateX(50px);
  z-index: 7004;
  transition: 0.75s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.btn-OurReco.previous2 {
  transform: scale(0.9) translateX(-50px);
  z-index: 7004;
  transition: 0.75s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.btn-OurReco.next2 {
  transform: scale(0.8) translateX(100px);
  z-index: 7003;
  transition: 0.75s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.btn-OurReco.previous1 {
  transform: scale(0.8) translateX(-100px);
  z-index: 7003;
  transition: 0.75s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

/* Version tablette */
@media screen and (max-width: 1500px) {
  .btn-OurReco {
    width: 350px;
    height: 350px;
  }
}

@media screen and (max-width: 1000px) {
  .pageBody {
    flex-direction: column-reverse;
  }
}

/* version mobile */
@media screen and (max-width: 769px) {
  .pageContainer .pageTitle {
    width: 80%;
    font-size: 16px;
    text-align: center;
  }

  .pageContainer h3 {
    font-size: 16px;
  }

  .pageBodyActivity {
    margin-top: 40px;
  }

  .btn-OurReco {
    width: 270px;
    height: 250px;
  }

  .pageContainer .pagePreviewOfImages {
    height: 260px;
  }

  .secondBody {
    max-width: calc(100% - 80px);
    margin: 0 40px;
  }
  .textSection h1 {
    font-size: 21px;
  }

  .textSection h2 {
    font-size: 18px;
  }
}
