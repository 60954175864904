/* CSS pour pc */

@media screen and (min-width: 770px) {
  .sousMenuE {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: center;
    min-height: 100vh;
    padding-top: 125px;
  }

  /* CSS des planetes */

  .robotiqueL {
    grid-column: 1;
    grid-row: 1;
  }
  .MercureLogoL {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .SemaineTM_L {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .ConcertOphoniusL {
    grid-column: 2;
    grid-row: 3;
  }
  .TousNosEvenementsL {
    grid-column: 3;
    grid-row: 3;
  }

  /* CSS des images de planetes */

  .imageRobotique {
    width: 250px;
    left: 30%;
    top: 50px;
    position: relative;
  }
  .MercureLogo {
    width: 640px;
    right: 20px;
    top: 0%;
    position: relative;
  }
  .imageSemaineTM {
    width: 320px;
    top: 50px;
    right: 130px;
    position: relative;
  }
  .imageConcertOphonius {
    width: 300px;
    right: 30%;
    bottom: 50px;
    position: relative;
  }
  .imageTousNosEvenements {
    width: 340px;
    right: 30%;
    bottom: 90px;
    position: relative;
  }

  /* CSS des textes des planetes */

  .texteRobotique {
    position: absolute;
    z-index: 10;
    top: 18%;
    left: 15%;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
  .texteLogoM {
    position: absolute;
    z-index: 10;
    top: 25%;
    right: 24%;
    font-size: 55px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
  .texteSemaineTM {
    position: absolute;
    z-index: 10;
    top: 35%;
    left: 15%;
    font-size: 35px;
    font-weight: 400;
    text-align: center;
    color: white;
    width: 20%;
  }
  .texteConcertOphonius {
    position: absolute;
    z-index: 10;
    bottom: 43%;
    right: 45%;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    color: white;
    width: 20%;
  }
  .texteTousNosEvenements {
    position: absolute;
    z-index: 10;
    bottom: 43%;
    right: 12%;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    color: white;
    width: 20%;
  }
}

/*  CSS pour le mobile */

@media screen and (max-width: 769px) {
  .sousMenuE {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: center;
    min-height: 100vh;
    padding-top: 70px;
  }

  /* CSS des planetes */

  .robotiqueL {
    grid-column: 1;
    grid-row: 2;
  }
  .MercureLogoL {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .SemaineTM_L {
    grid-column: 2;
    grid-row: 2;
  }
  .ConcertOphoniusL {
    grid-column: 2;
    grid-row: 3;
  }
  .TousNosEvenementsL {
    grid-column: 1;
    grid-row: 3;
  }

  /* CSS des images de planetes */

  .imageRobotique {
    width: 140px;
    left: -5%;
    top: 80px;
    position: relative;
  }
  .MercureLogo {
    width: 300px;
    left: 0%;
    top: 0%;
    position: relative;
  }
  .imageSemaineTM {
    width: 160px;
    top: 140px;
    right: 5%;
    position: relative;
  }
  .imageConcertOphonius {
    width: 130px;
    left: 0%;
    top: 50px;
    position: relative;
  }
  .imageTousNosEvenements {
    width: 160px;
    left: 0%;
    top: -10px;
    position: relative;
  }

  /* CSS des textes des planetes */

  .texteRobotique {
    position: absolute;
    z-index: 10;
    top: 35%;
    left: 7%;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
  .texteLogoM {
    position: absolute;
    z-index: 10;
    top: 13%;
    left: 20%;
    font-size: 35px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
  .texteSemaineTM {
    position: absolute;
    z-index: 10;
    top: 37%;
    right: 11%;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: white;
    width: 40%;
  }
  .texteConcertOphonius {
    position: absolute;
    z-index: 10;
    bottom: 43%;
    right: 5%;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: white;
    width: 40%;
  }
  .texteTousNosEvenements {
    position: absolute;
    z-index: 10;
    bottom: 48%;
    left: 8%;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: white;
    width: 40%;
  }
}

/* CSS pour tablette */

@media only screen and (min-width: 400px) and (max-width: 810px) {
  .MercureLogo {
    width: 200px;
  }
}
