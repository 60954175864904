.AjoutAdminForm {
  width: 800px;
  background-color: #c4c4c4;
  margin-bottom: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.AddAdminTitre {
  font-size: 20px;
  padding-bottom: 20px;
}
.AddAdminImg {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}
.AddAdminUpload {
  display: flex;
}
.ChoisirUnFichier {
  display: flex;
  justify-self: start;
}
.UploadBnt {
  width: 70px;
  display: flex;
  justify-content: center;
}
.AffichageImg {
  max-height: 170px;
  width: 200px;
  padding: 10px;
}
.SubmitAdminBnt {
  width: 300px;
  align-items: center;
}
.AddAdminDescription {
  display: flex;
  flex-direction: row;
  margin: 20px 0px;
  align-items: center;
}
.testThib {
  display: flex;
  flex-direction: row;
  background-color: #7c7c7c;
}
@media screen and (max-width: 769px) {
  .AffichageImg {
    max-height: 150px;
    width: 100px;
  }
}
