.listOfCategories {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
}

#head {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  color: transparent !important;
  background-color: transparent !important;
}

#head::-webkit-color-swatch-wrapper {
  padding: 0;
  color: transparent !important;
  background-color: transparent !important;
}

#head::-webkit-color-swatch {
  border: none;
  border-radius: 100%;
  color: transparent !important;
  background-color: transparent !important;
}

.colorPreview {
  margin-top: -30px;
  height: 30px !important;
  width: 30px !important;
}

/* XXL */
@media screen and (min-width: 1780px) { 
  .addNewCategoryForm {
    width: 70%;
  }
} 