.globalContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tropheesList {
  padding: 2%;
  margin: 2%;
  width: 80%;
  display: flex;
  align-items: center;
  background-color: #c4c4c4;
  justify-content: space-evenly;
  flex-wrap: wrap;
  border-radius: 4px;
}

.tropheeContainer {
  width: 30%;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #383636;
  color: #c4c4c4;
}

.modifySection {
  padding: 2%;
  border-radius: 4px;
  width: 80%;
  background-color: #c4c4c4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.colorRed {
  color: red;
  font-size: 1.1rem;
}

.modifySectionTxt {
  width: 100%;
  display: flex;
  /* align-items: center; */
}

.modifySectionTxtLeft {
  min-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.marginForm {
  margin-top: 5rem;;
}

.modifySectionTxtRight {
  min-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputElement {
  padding: 2%;
  width: 80%;
}

.inputElement input {
  width: 100%;
}

.inputElementTxt {
  padding: 2%;
  width: 80%;
  font-size: 1.5rem;
}

.tropheeBtn {
  margin-top: 30px !important;
}

.inputElementTxt textarea {
  min-height: 100px;
  width: 100%;
}

#AddSection {
  margin-top: 20px;
}

.tropheeContainer button {
  margin-top: 10px;
  border-radius: 4px;
  padding: 5px;
}

.submit-button {
  margin-top: 20%;
  padding: 20px;
  border-radius: 50px;
}
