.ArticlesDePresses {
  z-index: 0 !important;
}

/* MD */
@media screen and (min-width: 768px) { 
  .ArticlesDePresses {
    margin-top: -350px;
    margin-right: -350px;
  }
} 

/* CSS pour pc */

@media screen and (min-width: 770px) {
  .sousMenu {
    margin-bottom: 0px !important;
    height: 100% !important;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: center;
    min-height: 100vh;
    max-height: 1vh;
    margin-bottom: 40px;
  }

  .asteroPhotosGalerie {
    margin-top: 290px !important;
  }

  .marsGaler {
    margin-top: 110px;
  }

  /* CSS des planetes */

  .photosL {
    grid-column: 1;
    grid-row: 1;
  }
  .MoonLogoL {
    margin-right: -190px;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .vidéosL {
    margin-top: 110px;
    margin-left: -120px;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .ArticlesDePressesL {
    grid-column: 2;
    grid-row: 3;
  }

  /* CSS des images de planetes */

  .imagePhotos {
    width: 170px;
    left: 30%;
    top: 15px;
  }
  .MoonLogo {
    width: 300px;
    left: 0%;
    top: 0%;
    /* position: relative; */
  }
  .imageVidéos {
    width: 550px;
    top: 80px;
    right: 130px;
  }
  .imageArticlesDePresses {
    width: 350px;
    right: 30%;
    bottom: 30px;
  }

  /* CSS des textes des planetes */

  .textePhotos {
    z-index: 99999 !important;
    margin-top: -113px;
    left: 18%;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
  .texteLogoG {
    /* position: absolute; */
    z-index: 999999 !important;
    margin-top: -185px;
    /* margin-bottom: 260px; */
    right: 26%;
    font-size: 40px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
  .texteVidéos {
    z-index: 999999 !important;
    left: 22%;
    margin-top: -220px;
    margin-bottom: 220px;
    margin-left: -190px;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
  .texteArticlesDePresses {
    z-index: 99999 !important;
    margin-top: -200px;
    right: 47%;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
}

/*  CSS pour le mobile */

@media screen and (max-width: 769px) {

  .sousMenu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 0.3fr 0.5fr 0.5fr;
    justify-items: center;
    min-height: 100vh;
    margin-bottom: 40px;
  }

  .marsGaler {
    margin-top: 90px;
  }

  /* CSS des planetes */

  .photosL {
    margin-top: -60px;
    grid-column: 1;
    grid-row: 2;
  }
  .MoonLogoL {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .vidéosL {
    margin-top: -10px;
    grid-column: 2;
    grid-row: 3;
  }
  .ArticlesDePressesL {
    margin-top: -50px;
    grid-column: 1;
    grid-row: 4;
  }

  /* CSS des images de planetes */

  .imagePhotos {
    z-index: 0 !important;
    width: 160px;
    left: 10%;
    top: 0%;
  }
  .MoonLogo {
    width: 180px;
    left: 0%;
    top: 0%;
    /* position: relative; */
    z-index: 0 !important;
  }
  .imageVidéos {
    width: 150px;
    top: 0%;
    right: 10%;
  }
  .imageArticlesDePresses {
    width: 170px;
    left: 10%;
    top: 0%;
    position: relative;
  }

  /* CSS des textes des planetes */

  .textePhotos {
    z-index: 10;
    margin-top: -95px;
    left: 20%;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
  .texteLogoG {
    /* position: absolute; */
    z-index: 10;
    margin-top: -113px;
    left: 30%;
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
  .texteVidéos {
    margin-top: -80px;
    margin-left: -20px;
    z-index: 10;
    right: 20%;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
  .texteArticlesDePresses {
    width: 190px;
    position: relative;
    margin-top: -123px;
    left: 2%;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
}

a:hover {
  text-decoration: none !important;
}
