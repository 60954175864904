.globalContainer {
  /* height: 100vh; */
  overflow-x: hidden;
}

.upContainer {
  height: 40%;
  min-height: 400px;
  width: 100%;
  background-image: url("../../../image/Car-Plant.jpg");
  background-size: cover;
}

.bottomContainer {
  /*height: 60%;*/
  display: flex;
}

.leftSection {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40%;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

.tropheeSelector {
  padding-top: 3%;
  padding-bottom: 3%;
}

.tropheeSelectorButton {
  padding: 10px;
  margin: 30px;
}

.buttonSection {
  display: flex;
  justify-content: space-around;
}

.tropheebutton {
  background-color: rgb(32, 93, 169);
  border: none;
  padding: 15px 32px;
  color: white;
  border-radius: 30px;
  cursor: default !important;
  width: 260px !important;
  text-align: center;
}

/* .tropheebutton:hover {
  background-color: rgb(16, 181, 241);
} */

.bottomContainer {
  display: flex;
  width: 100%;
}

.texteContainer {
  display: flex;
  width: 80%;
}

.texteTrophee {
  margin: 3%;
  max-width: 30%;
}

@media screen and (max-width: 1650px) {
  .globalContainer {
    display: flex;
    flex-direction: column;
  }
  .bottomContainer {
    flex-direction: column;
  }

  .rightSection {
    justify-content: center;
    width: 100%;
  }

  .tropheeSelector {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .selectHeight {
    height: 40px;
    margin-top: 37px;
  }

  .buttonSection {
    width: 100%;
  }

  .texteContainer {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  .texteTrophee {
    margin: 3%;
    max-width: 100%;
  }
}
