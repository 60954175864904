/* XS */
@media screen and (max-width: 575px){ 
  .videoSize {
    width: 300px;
    height: 200px;
  }
  .galery-media {
    width: 100%;
  }
} 
/* SM */
@media screen and (min-width: 576px) and (max-width: 767px){ 
  .videoSize {
    width: 500px;
    height: 300px;
  }
  .galery-media {
    width: 80%;
  }
}

/* MD */
@media screen and (min-width: 768px) and (max-width: 991px){ 
  .galery-media {
    width: 60%;
  }
}

@media screen and (min-width: 1000px) {
  .galery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .galery-media {
    /* width: 40rem; */
    width: auto;
    height: 30rem;
    margin: 0.5rem;
  }
}

@media screen and (max-width: 769px) {
  .galery {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.btnRadius {
  border-radius: 20px !important;
  background-color: #687877 !important;
  padding: 10px !important;
  position: sticky !important;
  top: 0 !important;
}

.trashAlign {
  vertical-align: top !important;
}

.textDecoNone {
  text-decoration: none !important;
}

.btnRadius a {
  font-size: 1.1rem;
}

.stickyBackGallery {
  position: sticky !important;
  top: 0 !important;
}

/* XS */
@media screen and (max-width: 575px){ 
  .pdfStyleFront {
    border: 1px solid black;
    width: 100% !important;
    height: 430px !important;
  }
} 
/* SM */
@media screen and (min-width: 576px) { 
  .pdfStyleFront {
    width: 100% !important;
    height: 730px !important;
  }
}

.colorVoirDocu {
  background-color: rgb(206, 212, 212) !important;
}