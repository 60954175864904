.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #205da9;
  height: 100px;
  width: 100%;
  position: fixe;
  bottom: 0%;
}

.res {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style-type: none;
  width: 20%;
}

.copyright {
  width: 80%;
  display: flex;
  justify-content: start;
}

.resimage {
  width: 100%;
  max-width: 40px;
  height: auto;
  max-height: 40px;
}

.copyrightimg {
  width: 40px;
  margin-left: 40px;
}

@media screen and (max-width: 700px) {
  .copyright {
    width: 50%;
  }
  .res {
    width: 50%;
  }
}
