/* CSS pour pc */

@media screen and (min-width: 1220px) {
  .sousMenuActiv {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: center;
    min-height: 100vh;
    padding-top: 125px;
  }

  /* CSS des planetes */

  .placementTexteLune {
    width: 700px;
  }
  .PourVotreEnfantL {
    grid-column: 1;
    grid-row: 1;
  }
  .TerreLogoL {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .PourVotreEtablissementOuAssociationL {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .PourVotreCentreDeLoisirOuCollectivitéesL {
    grid-column: 2;
    grid-row: 3;
  }

  /* CSS des images de planetes */

  .imagePourVotreEnfant {
    width: 350px;
    left: 30%;
    top: 15px;
    position: relative;
  }
  .TerreLogo {
    width: 640px;
    left: 0%;
    top: 0%;
    position: relative;
  }
  .imagePourVotreEtablissementOuAssociation {
    width: 300px;
    top: 80px;
    right: 130px;
    position: relative;
  }
  .imagePourVotreCentreDeLoisirOuCollectivitées {
    width: 300px;
    right: 30%;
    bottom: 30px;
    position: relative;
  }

  /* CSS des textes des planetes */

  .textePourVotreEnfant {
    /* position: absolute; */
    position: relative;
    z-index: 99999;
    /* top: 18%;
    left: 14%; */
    margin-left: 220px;
    margin-top: -210px;;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    color: white;
    width: 320px;
  }
  .texteLogoT {
    /* position: absolute; */
    position: relative;
    margin-top: -350px;
    z-index: 99999;
    font-size: 35px;
    font-weight: 400;
    text-align: center;
    color: white;
    margin-right: 20px;
  }
  .textePourVotreEtablissementOuAssociation {
    position: relative;
    z-index: 10;
    font-size: 35px;
    font-weight: 400;
    text-align: center;
    color: white;
    width: 210px;
    margin-top: -130px;
    margin-left: -100px;
  }
  .PourVotreEtablissementOuAssociationActiv {
    margin-top: 120px;
    margin-bottom: -120px;
  }
}

.textePourVotreCentreDeLoisirOuCollectivitées {
  position: relative;
  z-index: 10;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  color: white;
  width: 190px;
  margin-top: -160px;
  margin-bottom: 140px;
}

.imageAsteroActiv {
  width: 180px;
}

/*  CSS pour le mobile */

@media screen and (max-width: 1219px) {
  .sousMenuActiv {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 0.3fr 0.5fr 0.5fr;
    justify-items: center;
    min-height: 100vh;
    padding-top: 60px;
  }

  /* CSS des planetes */

  .imagePourVotreEnfantL {
    grid-column: 1;
    grid-row: 2;
  }
  .TerreLogoL {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .PourVotreEtablissementOuAssociationL {
    grid-column: 2;
    grid-row: 3;
  }
  .PourVotreCentreDeLoisirOuCollectivitéesL {
    grid-column: 1;
    grid-row: 4;
  }

  /* CSS des images de planetes */

  .imagePourVotreEnfant {
    width: 160px;
    left: 10%;
    top: 0%;
    position: relative;
  }
  .TerreLogo {
    width: 300px;
    left: 0%;
    top: 0%;
    position: relative;
  }
  .imagePourVotreEtablissementOuAssociation {
    width: 170px;
    top: 0%;
    right: 10%;
    position: relative;
  }
  .imagePourVotreCentreDeLoisirOuCollectivitées {
    width: 110px;
    left: 10%;
    top: 0%;
    position: relative;
  }

  /* CSS des textes des planetes */

  .textePourVotreEnfant {
    position: relative;
    z-index: 10;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: white;
    margin-left: 20px;
    margin-top: -110px;
    margin-bottom: 110px;
    width: 150px;
  }
  .texteLogoT {
    position: relative;
    margin-top: -190px;
    z-index: 10;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: white;
    width: 220px;
    margin-left: 35px;
  }
  .textePourVotreEtablissementOuAssociation {
    position: relative;
    z-index: 10;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: white;
    width: 166px;
    margin-right: 30px;
    margin-top: -120px;
  }
  .PourVotreEtablissementOuAssociationTXT {
    margin-right: 50px;
  }
}
