/* XS */
@media screen and (max-width: 1199px){ 
    .customFusContainer {
        display: flex !important;
        flex-direction: column !important;
    }
    .customFusBloc {
        align-items: baseline;
        margin-top: 460px !important;
    }
    .customFusRightSite {
        height: 100%;
        margin-bottom: 50px;
    }
    .customNoBlank {
        margin-top: -300px;
        height: 690px !important;
        /* justify-content: center;
        width: 100%; */
    }
    .customMilieu {
        padding-left: 11px !important;   
        height: 100px; 
        width: 70px !important;   
    }
    .customTete {
        margin-left: 10px !important;
    }
    .customBas {
        margin-right: -10px !important;
    }
} 

/* XXL */
@media screen and (min-width: 1200px) { 

    .customFusContainer {
        /* height: 160vh !important; */
    }

} 
