.space {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.bcg-img {
  background: url("../../../../public/images/cieletoile.jpg")
    no-repeat center;
  background-size: cover;
}
