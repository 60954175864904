/* CSS pour pc */

.opacitePlanetes {
  filter: brightness(0.9);
}
/* LG */
@media screen and (min-width: 1020px){ 
  .planetesTexte {
    font-size: 1.7rem !important;
    font-weight: bold;
  }
  .earthTXT {
    width: 220px;
    margin-left: -20px;
    margin-top: 10px;
  }
  .planeteEvenements {
    margin-left: 30px !important;
  }
  .planeteGalerie {
    margin-left: 17px;
    margin-top: 10px;
  }
  .planeteQui {
    margin-left: 33px;
    margin-top: -8px;
  }
}

@media screen and (min-width: 700px) {
  .planetes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    margin-bottom: -60px;
    padding-top: 125px;
  }

  /* CSS du logo */
  .fondLogoAcc {
    background-color: white;
    border-radius: 50%;
    width: 300px;
    height: 300px;
    grid-column: 2;
    grid-row: 1;
    margin-top: 35%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 2em white;
  }

  /* CSS des planetes */
  .planeteL {
    grid-column: 3;
    grid-row: 2;
  }

  /* CSS des images de planetes */

  .imageTerre {
    width: 400px;
    padding-left: 30%;
    padding-top: 10%;
    position: relative;
  }
  .logoAcc {
    width: 100%;
    padding: 15px;
  }
  .imageMars {
    width: 250px;
    padding-top: 30%;
    position: relative;
  }
  .imageNeptune {
    width: 400px;
    margin-top: -20%;
    padding-right: 15%;
    position: relative;
  }
  .imagePlanete {
    width: 400px;
    padding-bottom: 20%;
    margin-left: -30%;
    position: relative;
  }

  /* CSS des textes des planetes */

  .terre {
    position: relative;
  }
  .texteTerre {
    position: absolute;
    top: 38% !important;
    left: 42% !important;
    z-index: 10;
    font-size: 27px !important;
    font-weight: 400;
    text-align: center;
    color: white;
  }

  .mars {
    position: relative;
    margin-top: 100px;
  }
  .texteMars {
    position: absolute;
    top: 50%;
    left: 20%;
    z-index: 10;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    color: white;
  }

  .neptune {
    position: relative;
  }
  .texteNeptune {
    position: absolute;
    top: 32%;
    left: -3%;
    z-index: 10;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    color: white;
  }

  .planete {
    position: relative;
  }
  .textePlanete {
    position: absolute;
    top: 40%;
    left: -10%;
    z-index: 10;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
}

@media screen and (min-width: 700px) and (max-width: 769px) {
  .planeteQui {
    margin-top: -10px !important;
    margin-right: 20px;
  }
  .planeteL {
    top: -200px !important;
  }
  .textePlanete {
    margin-left: -25px;
    margin-top: -6px;
  }
}

/*  CSS pour le mobile */

@media screen and (max-width: 769px) {
  .planetes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    justify-items: center;
    margin-bottom: -60px;
    padding-top: 55px;
  }

  .planeteEvenements {
    margin-left: 30px !important;
  }
  .neptune {
    margin-top: -40px;
  }
  .planeteQui {
    margin-top: -40px;
  }

  .planeteQui {
    margin-left: 10px;
  }

  /* CSS du logo */

  .fondLogoAcc {
    width: 0%;
  }

  /* CSS des planetes */

  .terreL {
    grid-column: 1;
    grid-row: 1;
    position: relative;
  }
  .marsL {
    grid-column: 2;
    grid-row: 2;
    position: relative;
  }
  .neptuneL {
    grid-column: 1;
    grid-row: 3;
    position: relative;
  }
  .planeteL {
    grid-column: 2;
    grid-row: 4;
    position: relative;
  }

  /* CSS des images de planetes */

  .logoAcc {
    width: 0%;
    padding: 15px;
  }
  .imageTerre {
    width: 160px;
    position: relative;
    left: 30px;
    top: 40px;
  }
  .imageMars {
    width: 170px;
    position: relative;
    left: -30px;
    top: 10px;
  }
  .imageNeptune {
    width: 200px;
    position: relative;
    left: 30px;
    top: 10px;
  }
  .imagePlanete {
    width: 150px;
    position: relative;
    left: -10px;
    top: -40px;
  }

  /* CSS des textes des planetes */

  .texteTerre {
    position: absolute;
    top: 76px;
    left: 55px;
    z-index: 10;
    /* font-size: 20px; */
    font-weight: 600;
    text-align: center;
    color: white;
  }
  .texteMars {
    position: absolute;
    top: 82px;
    left: 20px;
    z-index: 10;
    /* font-size: 20px; */
    font-weight: 600;
    text-align: center;
    color: white;
  }
  .texteNeptune {
    position: absolute;
    top: 95px;
    left: 50px;
    z-index: 10;
    /* font-size: 20px; */
    font-weight: 600;
    text-align: center;
    color: white;
  }
  .textePlanete {
    position: relative;
    top: -122px;
    left: -20px;
    z-index: 10;
    /* font-size: 20px; */
    font-weight: 600;
    text-align: center;
    color: white;
  }
}

/* CSS pour tablette */

@media only screen and (min-width: 700px) and (max-width: 1024px) {
  .fondLogoAcc {
    width: 250px;
    height: 250px;
    top: 35%;
    left: -10%;
  }
  .logoAcc {
    width: 80%;
    padding: 15px;
  }
  .imageTerre {
    width: 350px;
    position: relative;
    left: 30px;
    top: 25px;
  }
  .mars {
    top: -62px;
  }
  .imageMars {
    width: 180px;
    position: relative;
    left: -30px;
    top: 70px;
  }
  .imageNeptune {
    width: 230px;
    position: relative;
    left: 30px;
    top: 40px;
  }
  .imagePlanete {
    width: 250px;
    position: relative;
    left: -8px;
    top: -20px;
  }
  .ovale {
    top: 20% !important;
  }

  .texteTerre {
    position: absolute;
    top: 53%;
    left: 160px;
    z-index: 10;
    /* font-size: 30px; */
    font-weight: 600;
    text-align: center;
    color: white;
  }
  .texteMars {
    position: absolute;
    top: 80%;
    left: 0px;
    z-index: 10;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: white;
  }
  .texteNeptune {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 10;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: white;
  }
  .textePlanete {
    position: absolute;
    top: 90px;
    left: -60px;
    z-index: 10;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: white;
  }
}
