.login-div {
  display: flex;
  justify-content: center;
}

/* MD */
@media screen and (min-width: 768px) {
  .login {
    max-width: 50% !important;
  }
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: solid;
  max-width: 80%;
  border-radius: 15px;
  padding: 2rem;
  margin-top: 5rem;
  box-shadow: 10px 5px 10px gray;
}

.login-email {
  margin: 1rem;
  display: flex;
  justify-content: center;
}

.login-password {
  display: flex;
  justify-content: center;
  margin: 1rem;
  margin-right: 1rem;
  margin-right: 3rem;
}

#login-email {
  box-shadow: 10px 5px 5px gray;
}

#login-password {
  box-shadow: 10px 5px 5px gray;
}

.login img {
  max-width: 300px;
}