.animationList {
  display: flex;
  justify-content: center;
  padding: 10px 30px 20px;
  flex-wrap: wrap;
  width: 100%;
}

.animationList .animationCard {
  height: 200px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px 30px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.animationList .animationCard:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.animationList .animationCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.animationList .animationCard h3 {
  position: absolute;
  color: white;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.85);
}
