* {
  box-sizing: border-box;
  text-decoration: none;
  padding: 0;
  margin: 0;
}
<<<<<<< HEAD a:visited {
  color: white;
}
======= a:visited {
  text-decoration: none;
  color: white;
}

>>>>>>>dev .App {
  display: flex;
  width: 100vw;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.submitButton {
  border: none;
  color: white;
  background-color: limegreen;
  padding: 8px 16px;
  margin: 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 500ms;
}

.submitButton:hover {
  background-color: green;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.hidden {
  display: none;
}