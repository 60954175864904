.fusee-globalContainer {
  display: flex;
  margin: 0;
  padding: 0rem;
  background: url("../../public/images/cieletoile.jpg")
    no-repeat center;
  -webkit-background-size: cover;
  background-size: cover;
}
.fusee-globalContainer {
  display: flex;
}

.textMaxSize .fusee-textPres {
  height: 35rem;
  overflow-y: scroll;
}

.fusee-leftSide {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70rem;
}

.fusee-rightSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 70rem;
}

.fusee-fuseeBloc {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  width: 30%;
  height: 70rem;
  margin-bottom: 4rem;
}

.fusee-imgFusee {
  width: 100%;
}

/* XXL */
@media screen and (min-width: 1200px) { 
  .fuseeMilieu {
    margin-left: 10px;
  }
  
  .fuseeHaut {
    margin-left: 19px;
  }
} 

#fMid {
  display: none;
  width: 14rem;
}

#fHaut {
  display: none;
  width: 16rem;
}
#fBas {
  width: 30rem;
}

.fusee-textPres {
  width: 50%;
  background-color: rgba(237, 237, 237, 0.8);
  padding: 10%;
  border-style: groove;
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  font-size: 2rem;
}

#text2 {
  display: none;
}

#text3 {
  display: none;
}
.fusee-fusee-h2 {
  font-size: 3rem;
  background: -webkit-linear-gradient(#eee, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 3rem;
} 

@media screen and (max-width: 1199px) {
  .fusee-globalContainer {
    display: flex;
    flex-direction: column;
  }
  .fusee-rightSide {
    width: 100%;
    align-items: center;
    flex-direction: column-reverse;
  }
  .fusee-leftSide {
    justify-content: center;
    height: 30%;
    width: 100%;
  }

  .fusee-fuseeBloc {
    width: 80%;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 0;
  }

  .fusee-imgFusee {
    width: 30%;
    transform: rotate(90deg);
  }

  .fusee-textPres {
    width: 80%;
    font-size: 1rem;
  }
  #fBas {
    width: 8rem;
    margin-bottom: 0.4rem;
  }
  #fMid {
    width: 4rem;
  }
  #fHaut {
    width: 6rem;
    margin-left: 1rem;
    margin-bottom: 0.4rem;
  }
  .fusee-fusee-h2 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}
