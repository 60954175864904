

/* XS */
@media screen and (max-width: 575px){ 
    .evenemCustom {
        position: relative !important;
        top: -122px !important;
        left: -45px;
        margin-left: 60px;
        width: 140px;
    }
    .texteLogCustom {
        position: relative !important;
        top: -170px;
        left: 0px;
        /* background-color: rgb(13, 59, 34) !important; */
    }
    .asteroRobCustom {
        position: relative !important;
        margin-top: -17px !important;
        left: -10px !important;
    }
    .texteSemaineTCustom {
        position: relative !important;
        margin-top: 60px !important;
        left: 20px !important;
    }
    .texteConcOphoCustom {
        position: relative !important;
        margin-top: -70px !important;
        left: -20px !important;
        width: 160px;
    }
    
}  

.mercureLogCustom {
    width: 300px !important;
}

/* SM */
@media screen and (min-width: 576px) and (max-width: 767px){ 
    .texteLogCustom {
        position: relative !important;
        top: -170px;
        left: 0px;
        /* background-color: rgb(110, 150, 128) !important; */
    }
    .texteSemaineTCustom {
        position: relative !important;
        margin-top: 60px !important;
        left: 20px !important;
    }
    .asteroRobCustom {
        position: relative !important;
        margin-top: -17px !important;
        left: -10px !important;
    }
    .evenemCustom {
        position: relative !important;
        top: -122px !important;
        left: -45px;
        margin-left: 60px;
        width: 140px;
    }
    .texteConcOphoCustom {
        position: relative !important;
        margin-top: -70px !important;
        left: -20px !important;
        width: 160px;
    }
    
} 
/* MD */
@media screen and (min-width: 768px) and (max-width: 991px){ 
    .texteLogCustom {
        position: relative !important;
        top: -170px;
        left: -20px;
        /* background-color: rgb(30, 62, 167) !important; */
        font-size: 2rem;
    }
    .texteSemaineTCustom {
        position: relative !important;
        margin-top: -94px !important;
        left: -52px !important;
    }
    .asteroRobCustom {
        position: relative !important;
        margin-top: -127px !important;
        left: 70px !important;
    }
    .evenemCustom {
        position: relative !important;
        top: -322px !important;
        left: -65px;
        margin-left: 60px;
        width: 140px;
    }
    .texteConcOphoCustom {
        position: relative !important;
        margin-top: -300px !important;
        left: -38px !important;
        width: 160px;
    }
} 
/* LG */
@media screen and (min-width: 992px) and (max-width: 1199px){ 
    .texteLogCustom {
        position: relative !important;
        top: -170px;
        left: -20px;
        /* background-color: rgb(190, 172, 88) !important; */
        font-size: 2rem;
    }
    .texteSemaineTCustom {
        position: relative !important;
        margin-top: -94px !important;
        left: -52px !important;
    }
    .asteroRobCustom {
        position: relative !important;
        margin-top: -127px !important;
        left: 70px !important;
    }
    .evenemCustom {
        position: relative !important;
        top: -322px !important;
        left: -65px;
        margin-left: 60px;
        width: 140px;
    }
    .texteConcOphoCustom {
        position: relative !important;
        margin-top: -300px !important;
        left: -38px !important;
        width: 160px;
    }
    
} 
/* XL */
@media screen and (min-width: 1200px) and (max-width: 1779px) { 
    .texteLogCustom {
        position: relative !important;
        top: -170px;
        left: -20px;
        /* background-color: rgb(190, 88, 190) !important; */
        font-size: 2rem;
    }
    .texteSemaineTCustom {
        position: relative !important;
        margin-top: -94px !important;
        left: -52px !important;
    }
    .asteroRobCustom {
        position: relative !important;
        margin-top: -127px !important;
        left: 70px !important;
    }
    .evenemCustom {
        position: relative !important;
        top: -322px !important;
        left: -65px;
        margin-left: 60px;
        width: 140px;
    }
    .texteConcOphoCustom {
        position: relative !important;
        margin-top: -300px !important;
        left: -38px !important;
        width: 160px;
    }
} 
/* XXL */
@media screen and (min-width: 1780px) { 
    .texteLogCustom {
        position: relative !important;
        top: -170px;
        left: -20px;
        /* background-color: rgb(88, 187, 190) !important; */
        font-size: 2rem;
    }
    .texteSemaineTCustom {
        position: relative !important;
        margin-top: -94px !important;
        left: -52px !important;
    }
    .asteroRobCustom {
        position: relative !important;
        margin-top: -127px !important;
        left: 70px !important;
    }
    .evenemCustom {
        position: relative !important;
        top: -322px !important;
        left: -65px;
        margin-left: 60px;
        width: 140px;
    }
    .texteConcOphoCustom {
        position: relative !important;
        margin-top: -300px !important;
        left: -38px !important;
        width: 160px;
    }
} 
