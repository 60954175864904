.adminNous-paragraph {
  display: flex;
  justify-content: space-around;
  background-color: #c4c4c4;
  padding: 1rem;
  border-radius: 4px;
}
.adminNous-text {
  border-radius: 20px;
  padding: 1rem;
  font-size: 20px;
  width: 100%;
  height: 30rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  border: none;
}
.adminNous-coordinates {
  background-color: #c4c4c4;
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 4px;
}
/* .adminNous-coordinate {
  background-color: #828282;
} */
.nous-coordinate {
  font-size: 20px;
  height: 2.5rem;
  border-radius: 20px;
  padding: 1rem;
  text-align: center;
  justify-content: space-between;
  display: flex;
  border: none;
}
.adminNous-input {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 3rem;
}
.adminNous-div-button {
  display: flex;
  justify-content: center;
}
.adminNous-button {
  width: 10rem;
  background-color: #828282;
  height: 4rem;
  margin-top: 1rem;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1.5rem;
}
.paragrapheNous {
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: none;
}
.titreNous {
  padding-bottom: 20px;
  font-size: 20px;
}
.Modifnous {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.adminNous-data {
  padding: 10px;
  font-size: 18px;
}
.adminNous-coordinate {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
