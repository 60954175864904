.imageLogo {
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 50%;
  margin-left: 11px;
  margin-top: 13px;
}

.burger {
  width: 75px;
  height: 75px;
  position: absolute;
  left: 85%;
  top: 10px;
}

/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 12; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
#overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  height: 40%;
  z-index: 12;
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/* The navigation links inside the overlay */
.overlay a {
  padding: 8px;
  text-decoration: none;

  color: #818181;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 10px;
  left: 83%;
  font-size: 60px;
}

.closebtn {
  width: 65px;
  height: 55px;
  padding: 0px;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

#myNav-open {
  width: 100%;
  z-index: 11;
}

#myNav-close {
  width: 0%;
}

.NvMob {
  display: none;
}

/* Css pour la partie Desktop ! */

.NvDesk {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.logo {
  width: 125px;
  margin: 1%;
  padding: 10px;
}
.fondLogoNb {
  background-color: white;
  border-radius: 50%;
  position: relative;
  /* height: 50px; */
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.divbutton {
  width: 80%;
  margin-left: 70px;
}

.buttonagencement {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 0px;
  justify-content: flex-end;
}

.nousContacter:hover {
  text-decoration: none;
}

/* .nousContacter {
  width: 130px;
} */
.nousContac {
  width: 190px !important;
}

.dons {
  height: 8rem !important;
  width: 11.5rem !important;
  /* margin-left: 20rem !important; */
}

.label {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
}

.button {
  height: 40%;
  width: 100%;
  border-radius: 50px;
  background: transparent;
  border: none;
  color: white;
  font-size: large;
  background: rgba(127, 127, 127, 0.5);
}

/* Basculement navigation */

@media screen and (max-width: 600px) {
  .NvDesk {
    display: none;
  }

  .NvMob {
    display: block;
    position: absolute;
    z-index: 12;

    width: 100%;
  }

  .burger {
    width: 45px;
    height: 40px;
    margin-top: 10px;
  }
}

.buttonnavmobile {
  border-radius: 50px;
  width: 50%;
  height: 20%;
  font-size: large;
  border-radius: 50px;
  background: transparent;
  border: none;
  color: white;
  font-size: large;
  background: rgba(127, 127, 127, 0.5);
}

#overlay-none {
  display: none;
}

/* XL */
@media screen and (min-width: 1200px) and (max-width: 1779px) { 
  .refNav {
    padding-top: 4px;
  }
} 
/* XXL */
@media screen and (min-width: 1780px) { 
  .refNav {
    padding-top: 10px;
  }
}  