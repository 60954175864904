.adminApp {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.AdminComp {
  display: flex;
  flex-direction: column;
  padding: 25px 50px;
}

.AdminComp a {
  color: black;
}

.navGauche {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  padding-top: 50px;
  width: 100%;
}

.navBar {
  height: auto;
  width: 300px;
  min-height: 170vh;
  background-color: #2b2828;
  padding-top: 10px;
}

.box {
  display: block;
  height: 66px;
  color: white;
  padding-left: 14px;
}

.box:hover {
  text-decoration: none !important;
  color: #e4e4e4;
}

.present {
  display: block;
  background-color: #a3a0a0;
  height: 66px;
  color: black;
}

.textNav a {
  display: flex;
  align-items: center;
  width: 100%;
}

.textNav {
  width: 100%;
}

.imageLogo {
  width: 100px;
  height: 85px;
  background-color: white;
  border-radius: 50%;
  margin-left: 11px;
  margin-top: 19px;
}

.fondLogo {
  background-color: white;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-left: 10px;
  box-shadow: 0 0 1em white;
}

.version {
  position: fixed;
  bottom: 2%;
  left: 10px;
  color: white;
  font-size: 20px;
}

.TopBar {
  background-color: #2b2828;
  height: 74px;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.texteBvn {
  color: white;
  font-size: 20px;
  margin-right: 30px;
  width: 200px;
}

.imageAvatar {
  border-radius: 50%;
  width: 65px;
  height: 65px;
  margin-right: 10px;
}

.partenaires {
  background-color: #c4c4c4;
  height: 356px;
  width: 256px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 17px;
}

.TopPart {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.LogoIcon {
  height: 45px;
  width: 42px;
  margin: 10px;
  cursor: pointer;
}

.NomPart {
  font-size: 20px;
  padding-left: 10px;
  width: 50%;
}

.LogoPart {
  background-color: #7c7c7c;
  width: 238px;
  height: 258px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogoPart img {
  max-width: 100%;
  max-height: 100%;
}

a {
  display: inline-block;
}

.nvComp {
  display: flex;
}

.totuAdmin {
  display: flex;
  flex-direction: row;
}

.Card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.AddPartenaire {
  width: 30vw;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 20px;
}

.imageAdd {
  width: 47px;
  height: 47px;
  margin-right: 20px;
}

.AddNvPart {
  font-size: 20px;
}

.space {
  width: 100%;
  height: 368px;
}

.AdminComp {
  display: flex;
  flex-direction: column;
  padding: 25px 50px;
}

.adminModification {
  display: flex;
  align-items: center;
}

.login {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btnRobotFromEvent {
  color: rgb(0, 0, 0) !important;
  background-color: #C4C4C4 !important;
  height: 210px !important;
  min-height: 100px !important;
  text-align: left !important;
  width: auto !important;
}

.imageRobotEvent {
  margin-top: 30px;
  width: 100%;
  height: 125px;
  object-fit: cover !important;
}

.pointer {
  cursor: pointer;
}

.noColorHover:hover {
  color:rgb(0, 0, 0);
}

.hrNavStyle {
  border-top: 1px solid white;
}