/* h3 {
  font-size: 1rem;
} */

.accueil {
  background-image: url("../../../public/images/cieletoile.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
  min-height: 100vh;
}
.ovale {
  opacity: 0.4;
  position: absolute;
  left: 10%;
  top: 11%;
  width: 80%;
  box-shadow: 0 0 2em white;
  border-radius: 50%;
}
.NosPartenaires {
  color: white;
  font-size: 36px;
}
.NosPartenairesD {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 769px) {
  .ovale {
    width: 0%;
  }
  .NosPartenaires {
    color: white;
    font-size: 20px;
  }
}
.refNav {
  /* position: absolute; */
  font-size: 1.2rem;
  /* margin-top: 10rem; */
}