.titreEvent2 {
  color: white;
}
.imageEvent2 {
  height: 100px;
  border-radius: 10px;
  width: 200px;
  object-fit: cover;
}
.cardEvent {
  background-color: gray;
  display: flex;
  flex-direction: row;
  margin: 20px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 450px;
}
.cardEvent::before {
  opacity: 0.7;
}
.AllEvents {
  overflow: auto;
  height: 500px;
  width: 510px;
  margin-top: 2%;
}
.titleEvent2 {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 1.6rem;
  margin-top: 15px;
}
.retourListEvents {
  grid-column: 2;
  grid-row: 1;
  cursor: pointer !important;
  color: white;
  height: 20px;
  font-size: 2rem;
  margin-left: 160px;
  margin-top: -10px;
  margin-bottom: 60px;
}

.retourListEvents:hover {
  text-decoration: none;
  color: rgb(212, 212, 212);
}

.AfficherAllEvent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 250px;
}
.TrouNoir {
  grid-column: 2;
  grid-row: 1;
}
.TrouNoirImg {
  width: 500px;
}
.TrouNoirTexte {
  color: white;
  font-size: 40px;
  top: 37%;
  left: 54%;
  position: absolute;
}

/* CSS pour mobile */

@media screen and (max-width: 769px) {
  .AfficherAllEvent {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    margin: center !important;
  }
  .TrouNoir {
    grid-row: 1;
  }
  .AllEventsL {
    grid-row: 2;
  }
  .TrouNoirImg {
    width: 350px;
  }
  .cardEvent {
    background-color: gray;
    display: flex;
    flex-direction: row;
    margin: 20px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    width: 335px;
  }
  .AllEvents {
    overflow: auto;
    height: 300px;
    width: 375px;
    margin-top: 2%;
  }
  .TrouNoirTexte {
    color: white;
    font-size: 40px;
    top: 19%;
    left: 20%;
    position: absolute;
  }
}

/* CSS pour tablette */

@media only screen and (min-width: 770px) and (max-width: 1010px) {
  .AfficherAllEvent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 250px;
  }
  .cardEvent {
    background-color: gray;
    display: flex;
    flex-direction: row;
    margin: 20px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    width: 335px;
  }
  .AllEvents {
    overflow: auto;
    height: 400px;
    width: 375px;
    margin-top: 2%;
  }
  .TrouNoirImg {
    width: 400px;
  }
  .TrouNoirTexte {
    color: white;
    font-size: 40px;
    top: 300px;
    left: 500px;
    position: absolute;
  }
}
