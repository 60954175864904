/* PAGE AVEC TOUTES LES ANIMATIONS AFFICHEES */

.existingAnimation {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #c4c4c4;
  width: 100%;
  margin: 30px 0;
  border-radius: 4px;
}

.animationHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0 20px;
}

.animSuppr {
  width: 200px;;
}

.alignLeft {
  display: flex;
  justify-content: baseline;
}

.animationBody {
  display: flex;
  background-color: #909090;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px 20px;
  width: calc(100% - 30px);
  height: 190px;
}

.animationBody .animationImage {
  height: 100%;
  width: 75%;
  background-color: #c4c4c4;
  object-fit: cover;
}

.animationImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.animationBody .animationInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  max-width: 25%;
  padding: 10px;
}

.addAnimationForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.addAnimationForm .mainTitle {
  width: 75%;
  border: none;
  font-size: 30px;
  color: black;
  text-align: center;
  padding: 25px;
}

.addAnimationForm .subtitle {
  width: 50%;
  border: none;
  font-size: 20px;
  color: darkgray;
  padding: 20px;
}

.titreModify {
  text-transform: uppercase;
  background: #12b5f1;
  background-size: auto;
  padding: 10px !important;
  border-radius: 50px;
  color: white !important;
  /* width: 20rem !important; */
  width: 100% !important;
  font-size: 1.4rem !important;
  display: inline !important;
}

.tutoAdmin {
  font-weight: bold !important;
  font-size: 1.5rem !important;
}

.sousTitre {
  font-size: 2.4rem;
}

.subtitle {
  color: black !important;
  font-size: 2rem !important;
}

.alignLeft {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.alignLeft input {
  margin-right: 10px;
}

.addCategories {
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
  margin: 30px 0;
}

.addAnimationForm .feedback {
  padding: 20px;
}

/* CSS POUR LA SECTION D'AJOUT DE CATEGORIE*/

.addCategories p {
  padding: 25px 0;
}

.addCategories label {
  padding: 10px 0;
}

.addCategories .existingCategories {
  padding: 10px;
  border: solid 1px black;
}

.buttonCategory {
  position: relative;
  border: none;
  color: white;
  font-size: 12px;
  padding: 8px 16px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
}

.buttonCategory:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.addNewCategoryButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addNewCategoryButton img {
  height: 20px;
  width: 20px;
  margin: 0 20px;
}

.addNewCategoryForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.addNewCategoryForm .formRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 180px;
}

.addAnimationForm .hidden {
  display: none;
}

.selectedCategories {
  padding: 10px;
  min-height: 50px;
  border: solid 1px;
}

.colorPreview {
  background-color: gray;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.deleteCategory {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -7px;
  right: -7px;
  border: none;
  height: 20px;
  width: 20px;
  background-color: lightgray;
  border-radius: 20px;
}

.deleteCategory img {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

/* CSS POUR LA SECTION D'AJOUT DE TEXTE*/

.textFormatSelection {
  display: flex;
  justify-content: space-around;
  padding: 10px 20px 40px;
}

.addTextButton {
  display: flex;
  align-items: center;
  border: none;
  color: black;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: all 500ms;
}

.addTextButton:hover {
  background-color: lightgray;
}

.addIcon {
  width: 15px;
  margin-left: 10px;
}

.textsCreated {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.textsCreated .h1 {
  width: 70%;
  text-align: center;
  border: none;
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
  margin: 20px auto;
  transition: all 500ms;
}

.textsCreated .h2 {
  width: 90%;
  border: none;
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  margin: 10px 5px;
  transition: all 500ms;
}

.textsCreated .paragraphe {
  width: 90%;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  padding: 5px 20px;
  margin: 5px;
  transition: all 500ms;
}

.textsCreated .deleteh1 {
  right: 20%;
  height: 25px;
  width: 25px;
  top: 35%;
}

.textsCreated .deleteh2 {
  right: 14.5%;
  height: 25px;
  width: 25px;
  top: 30%;
}

.textsCreated .deleteparagraphe {
  right: 10%;
  height: 25px;
  width: 25px;
  top: 10%;
}

.textsCreated .up-and-down {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50px;
  width: 30px;
}

.textsCreated .uad-h1 {
  right: 16%;
  top: 17%;
}

.textsCreated .uad-h2 {
  right: 10.5%;
  top: 8%;
}

.textsCreated .uad-paragraphe {
  right: 9.5%;
  top: 38%;
}

.textsCreated .up-and-down button {
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 1px;
  border: none;
  border-radius: 50px;
}

.textsCreated .up-and-down button:hover {
  background-color: white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
}

.textsCreated .down-btn {
  transform: rotate(180deg);
}

.textsCreated .up-and-down img {
  height: 10px;
}

/* CSS POUR L'AJOUT D'IMAGES */
.uploadPreview {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.addCategories h3 {
  padding: 20px 0;
}
.uploadFormAnimations {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.uploadPreview img {
  max-width: 200px;
  max-height: 150px;
  margin: 15px;
  object-fit: cover;
}

.imagesPreviewRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imagesPreviewRow input {
  width: 300px;
}

.addCategories .activitiesPreview {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
